import _defineProperty from "/workdir/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";
import _objectWithoutProperties from "/workdir/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/objectWithoutProperties.js";
var _excluded = ["children", "className", "controls", "url"],
    _excluded2 = ["children"];

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

import { AnimatePresence } from 'framer-motion';
import dynamic from 'next/dynamic';
import { v4 as uuid } from 'uuid';
import { withDesignTokens } from '@segment/matcha/useDesignTokens';
import * as views from './VideoPlayer.views';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
var ReactPlayer = dynamic(function () {
  return import("react-player/lazy");
}, {
  ssr: false,
  loadableGenerated: {
    webpack: function webpack() {
      return [require.resolveWeak("react-player/lazy")];
    }
  }
});
export var VideoPlayer = function VideoPlayer(_ref) {
  var children = _ref.children,
      className = _ref.className,
      _ref$controls = _ref.controls,
      controls = _ref$controls === void 0 ? true : _ref$controls,
      originalUrl = _ref.url,
      reactPlayerProps = _objectWithoutProperties(_ref, _excluded);

  var url = originalUrl.includes("fast.wistia.com") ? originalUrl.replace("/medias", "/iframe") : originalUrl;
  return _jsxs(views.Wrapper, {
    className: className,
    children: [_jsx(AnimatePresence, {
      children: children
    }, uuid()), _jsx(ReactPlayer, _objectSpread({
      width: "100%",
      height: "100%",
      style: {
        position: "absolute",
        top: 0,
        left: 0,
        bottom: 0,
        right: 0
      },
      controls: controls,
      url: url
    }, reactPlayerProps))]
  });
};
var buttonVariants = {
  initial: {
    opacity: 0,
    transition: {
      delay: 0.3
    }
  },
  animate: {
    opacity: 1,
    transition: {
      duration: 0.3
    }
  },
  exit: {
    opacity: 0,
    transition: {
      duration: 0.3
    }
  }
};

var BaseBigPlayButton = function BaseBigPlayButton(_ref2) {
  var onClick = _ref2.onClick,
      _ref2$ariaLabel = _ref2.ariaLabel,
      ariaLabel = _ref2$ariaLabel === void 0 ? "Watch the video" : _ref2$ariaLabel,
      _ref2$variant = _ref2.variant,
      variant = _ref2$variant === void 0 ? "filled" : _ref2$variant,
      tokens = _ref2.tokens,
      _ref2$maskId = _ref2.maskId,
      maskId = _ref2$maskId === void 0 ? "cut-off-play" : _ref2$maskId;
  var label = variant === "text" && ariaLabel;
  return _jsx(views.ButtonWrapper, _objectSpread(_objectSpread({
    variant: variant
  }, tokens), {}, {
    children: _jsxs(views.Button, _objectSpread(_objectSpread({
      "aria-label": ariaLabel,
      onClick: onClick,
      initial: "initial",
      animate: "animate",
      exit: "exit",
      variant: variant,
      variants: buttonVariants
    }, tokens), {}, {
      children: [_jsxs(views.PlayIcon, _objectSpread(_objectSpread({
        viewBox: "0 0 150 150",
        fill: "none",
        xmlns: "http://www.w3.org/2000/svg",
        animate: "animate",
        variant: variant,
        variants: buttonVariants
      }, tokens), {}, {
        children: [_jsx("defs", {
          children: _jsxs("mask", {
            id: maskId,
            children: [_jsx("rect", {
              x: "0",
              y: "0",
              width: "150",
              height: "150",
              rx: "75",
              stroke: "current",
              fill: "white"
            }), _jsx("path", {
              transform: "translate(56, 43)",
              d: "M52 30L0 60L2.62462e-06 0L52 30Z",
              fill: "black"
            })]
          })
        }), _jsx("rect", {
          className: "circle",
          x: "3",
          y: "3",
          width: "144",
          height: "144",
          rx: "72",
          fill: "current",
          stroke: "current",
          strokeWidth: "6",
          mask: "url(#".concat(maskId, ")")
        }), _jsx("path", {
          className: "triangle",
          transform: "translate(56, 43)",
          d: "M52 30L0 60L2.62462e-06 0L52 30Z",
          fill: "current"
        })]
      })), label && _jsx(views.ButtonLabel, _objectSpread(_objectSpread({}, tokens), {}, {
        children: label
      }))]
    }))
  }));
};

var coverVariants = {
  hidden: {
    opacity: 0,
    transition: {
      delay: 0.3
    },
    transitionEnd: {
      zIndex: -1
    }
  },
  show: {
    opacity: 1,
    zIndex: 1
  }
};
export var Cover = function Cover(_ref3) {
  var children = _ref3.children,
      rest = _objectWithoutProperties(_ref3, _excluded2);

  return _jsx(views.Cover, _objectSpread(_objectSpread({
    initial: "hidden",
    animate: "show",
    exit: "hidden",
    variants: coverVariants
  }, rest), {}, {
    children: children
  }));
};
export var BigPlayButton = withDesignTokens(BaseBigPlayButton, function () {
  return {};
}, {
  light: function light(theme) {
    return {
      color: theme.palette.neutrals.white[100],
      backgroundColor: theme.palette.twilio.blue[60]
    };
  },
  dark: function dark(theme) {
    return {
      color: theme.palette.neutrals.white[100],
      backgroundColor: theme.palette.twilio.blue[60]
    };
  },
  twilioLight: function twilioLight(theme) {
    return {
      color: theme.palette.neutrals.white[100],
      backgroundColor: theme.palette.twilio.blue[60]
    };
  }
});