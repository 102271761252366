/**
 * A helper function to generate the path to an image from Contentful.
 * @param props.src - The path to the image
 * @param props.width - The width of the image
 * @param props.quality - The quality of the image
 * @returns The path to the image with the width and quality parameters
 */
var contentfulImageLoader = function contentfulImageLoader(_ref) {
  var src = _ref.src,
      width = _ref.width,
      quality = _ref.quality;
  return "".concat(src, "?w=").concat(width, "&q=").concat(quality || 75);
};

export default contentfulImageLoader;